import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SdfLink } from "@synerg/react-components";
import { removeExtraSpacesFromString } from "../../utility/stringUtil";
import useMediaQuery from "../../hooks/useMediaQuery";
import './relatedGuides.scss';
import { NO_PRINT_CLASS } from "../../utility/constants";


const CONTENT = '-ml-px cursor-pointer font-bold no-underline hover:underline text-action-primary border-solid rounded-none p-3 text-md border-r-0 border-b-0 border-t-0 border-4 -mr-3 py-3 border-transparent'
const SELECTED_CONTENT = CONTENT.replace('border-transparent', '')


export function TableOfContents({ currentGuideSections, guideType }) {
    function scrollToElement(element, offsetTopMargin = 80, behavior = 'smooth', container = null) {
        if (!element) return

        const containerElement = container || window
        containerElement.scrollTo({
            behavior,
            top: (element.offsetTop - offsetTopMargin)
        })
    }
    const getArticleContents = useCallback(() => {
        let guide = document.getElementById('doc-preview');
        if (!guide) return [];

        let contents = guide.querySelectorAll('*')

        return Array.from(contents)
        .filter((tag) => (tag.innerText && tag.innerText.trim() !== '' || tag.tagName === 'IMG' || (tag.tagName === 'P' && tag.querySelector('img')) ))
            .map((tag, index) => {
                tag.id = index
                return tag
            })
            || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGuideSections])

    const filterByTag = (arr, tagName) => arr.filter((tag) => tag.tagName === tagName)

    const h2sList = useMemo(() => {
        const parser = new DOMParser()
        const titleAsString = currentGuideSections.map(section => `<h2>${section.title}</h2>`).join('')
        const html = parser.parseFromString(titleAsString, 'text/html');

        return Array.from(html.body.querySelectorAll('h2')).filter(ele =>ele.innerHTML.trim() !== '&nbsp;')
        .map((ele, index) => {
            const id = `h2-${index + 1}`;
            ele.id = id;
            return ele
        })

    }, [currentGuideSections])

    const isLgOrBigger = useMediaQuery('(min-width: 1024px)')
    const [renderContentsMenu, setRenderContentsMenu] = useState(false)
    const [currentChapterNumber, setCurrentChapterNumber] = useState();
    let contentsElementsArray = getArticleContents()
    let h2s = filterByTag(contentsElementsArray, 'H2')
    const scrollOffsetTopMargin = 90;
    const lastScrollTop = useRef(0);

    function getInitialHighlighted() {
        return h2sList[0]
    }

    function customSetHighlighted(element) {
        if (!element) return

        //verify if its a element from content and not from toc
        if (element.className) {
            element = document.getElementById(getClassName(element));
        }

        const toc = document.querySelector(".table-of-contents")
        if (!toc) return

        const ele = toc.querySelector(`[id='${element.id}']`);
        scrollToElement(ele, 80, 'smooth', toc)
    }

    function updateInitialHighlighted(element) {
        if (!element) return;

        customSetHighlighted(element)

        const initialHighlighted = document.getElementById('guideContent')
        scrollToElement(initialHighlighted, scrollOffsetTopMargin, 'instant')
    }

    function updateMarginBottom() {
        if (!h2sList?.length) return;

        contentsElementsArray = getArticleContents()
        h2s = filterByTag(contentsElementsArray, 'H2')
        const lastH2 = h2s[h2s.length - 1]
        const lastH2Id = parseInt(lastH2?.id)

        let noPrintElements = 0;
        let lastBelowLastH2;
        Array.from(document.getElementsByClassName(NO_PRINT_CLASS) || [])
            .forEach((elm) => {
                if (elm.offsetHeight > 500) return
                noPrintElements += elm.offsetHeight
            }
            )
        contentsElementsArray.forEach((elm) => {
            if (lastH2Id > parseInt(elm.id)) return
            lastBelowLastH2 = elm
        })
        const lastH2Rect = lastH2?.getBoundingClientRect();
        const belowLastH2Rect = lastBelowLastH2?.getBoundingClientRect();

        let sum =
            // window
            window.innerHeight
            // header and footer
            - noPrintElements
            // distance between h3 and last element
            - (belowLastH2Rect?.bottom - lastH2Rect?.bottom)

        if (!lastH2?.parentElement?.parentElement?.style) return
        sum = sum - 66;
        lastH2.parentElement.parentElement.style.marginBottom = (sum <= 0 || !lastH2?.parentElement?.style) ? '0' : sum + 'px'

    }

    useEffect(() => {
        updateInitialHighlighted(
            getInitialHighlighted()
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGuideSections])

    useEffect(() => {
        setRenderContentsMenu(true)
    }, [])

    const getClassName = (element)=>{
        return Array.from(element?.classList).find((className)=> className.startsWith('h2-'));
    }

    const handleContentsHighlighting = useCallback(() => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const fastScroll = scrollTop - lastScrollTop.current;
        const scrollDown = scrollTop > lastScrollTop.current;
        lastScrollTop.current = scrollTop
        contentsElementsArray = getArticleContents()
        let filtered = contentsElementsArray.filter((elm) => {
            // filter elements outside of current view
            const rect = elm.getBoundingClientRect()
            return (
            elm.className.includes('simple') &&
                rect.left >= 0 &&
                rect.top >= 66 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        })

        if (!(filtered && filtered.length > 0)) return

        const chapterElement = filtered[0]
        // check if chapter is at top, if not set previous chapter as current
        const rect = chapterElement.getBoundingClientRect();
        const chapterNumber = chapterElement.getAttribute('chapter-id');

        if (rect.top >= 130 && chapterNumber > 1) {
            if (chapterElement?.attributes) {
                if (currentChapterNumber !== +chapterNumber - 1) {
                    customSetHighlighted(h2sList[chapterNumber - 2]);    
                    setCurrentChapterNumber(+chapterNumber - 1);
                }
            }

        } else {
            if (currentChapterNumber !== +chapterNumber) {
                customSetHighlighted(chapterElement);
                setCurrentChapterNumber(+chapterNumber);
            }
        }


        if(currentGuideSections.length === +chapterNumber && currentChapterNumber !== +chapterNumber && scrollDown)
        {
            setTimeout(()=>{
               if(+chapterNumber !== currentChapterNumber && (guideType === "productIntegration" && fastScroll >= 100 || guideType === "mkplIntegration" && fastScroll >= 40))
                {
                    customSetHighlighted(h2sList[chapterNumber - 1])
                    setCurrentChapterNumber(+chapterNumber);
                }
            }, 400)
        }

    }, [currentChapterNumber, currentGuideSections])


    useEffect(() => {
        document.addEventListener('scroll', handleContentsHighlighting)

        return () => {
            document.removeEventListener('scroll', handleContentsHighlighting)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChapterNumber, currentGuideSections])

    useEffect(() => {
        // add margin bottom to last chapter for scroll position detection to select correct chapter link in TOC
        setTimeout(() => {
            updateMarginBottom();
        }, 500);
    }, [])

    function loadNextChapterUntilFindElement(id, chapterNumber = 1) {
        const [elementOnDom] = document.getElementsByClassName(id)

        if (elementOnDom) {
            scrollToElement(elementOnDom, scrollOffsetTopMargin)
        }
    }

    function handleContentMenuByClick(id, chapterNumber = 0) {
        loadNextChapterUntilFindElement(id, chapterNumber)
    }

    function handleContentMenuByKeyPress(id, e) {
        if (e.key === 'Enter' && e.charCode === 13) {
            loadNextChapterUntilFindElement(id)
        }
    }

    if (!isLgOrBigger) return null

    return (
        <>
            {
                renderContentsMenu &&
                    h2sList.length > 0 ? (
                    <div className="w-xs mb-6 mt-8 pl-6 pt-6 pr-2 pb-2 sticky bg-alert-neutral-50 table-of-contents">
                        <div id="toc">
                            <div className="mb-3 text-lg font-bold">Contents</div>
                            <div className={`flex flex-col `}>
                                {
                                    h2sList.map((item, index) => {
                                        return <SdfLink
                                            key={index}
                                            id={item.id}
                                            className={`${currentChapterNumber === index + 1 ? SELECTED_CONTENT : CONTENT} h2-style w-10/12`}
                                            onClick={() => handleContentMenuByClick(item.id, index + 1)}
                                            onKeyPress={(e) => handleContentMenuByKeyPress(item.id, e)}
                                        >
                                            {removeExtraSpacesFromString(item.innerText)}
                                        </SdfLink>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                ) :
                    <div className="md:w-52 ml-16"></div>
            }
        </>

    )
}

